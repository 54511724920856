<template>
  <v-app>
    <router-view />
    <UpdateWrapper />
  </v-app>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue'
import UpdateWrapper from './components/UpdateAlert.vue'

// Local storage keys
const email = ref(localStorage.getItem('email') || '')
const user = ref(JSON.parse(localStorage.getItem('user')) || {
  authenticated: false,
  name: '',
  email: '',
  picture: '',
  token: ''
})
const synchronized = ref(Number(localStorage.getItem('synchronized')) || 0)
const notShowTutorial = ref(localStorage.getItem('notShowTutorial') === 'true')
const beta = ref(localStorage.getItem('beta') === 'true')
const reliable = ref(localStorage.getItem('reliable') === 'true')

// Optionally, update localStorage when values change
onMounted(() => {
  watch(email, (newVal) => localStorage.setItem('email', newVal))
  watch(user, (newVal) => localStorage.setItem('user', JSON.stringify(newVal)))
  watch(synchronized, (newVal) => localStorage.setItem('synchronized', newVal))
  watch(notShowTutorial, (newVal) => localStorage.setItem('notShowTutorial', newVal.toString()))
  watch(beta, (newVal) => localStorage.setItem('beta', newVal.toString()))
  watch(reliable, (newVal) => localStorage.setItem('reliable', newVal.toString()))
})
</script>
